import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  HStack,
  Center,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
  useToast,
  Input,
  Radio,
  RadioGroup,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  Tooltip,
} from "@chakra-ui/react";
import {
  MdArrowBack,
  MdFilterList,
  MdRefresh,
  MdOutlineHighlightOff,
  MdCheck,
  MdOutlineCheckCircleOutline,
  MdOutlineImportExport,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  getRevisionAutoelevadores,
  getRevisionDosificadoras,
  getRevisionMiniPala,
  getRevisionSanders,
  getRevisionGeneradores,
  getRevisionLuminaria,
  getRevisionBaseSV,
  getRevisionSandvan,
} from "../operaciones/helpers/api.helper";
import { getUsuarios } from "../usuarios/helpers/api.helper";
import DescargaRevision from "../operaciones/components/curso/revision/DescargaRevision";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../utils/sortData";

const RevisionTurnos = (props) => {
  const { mobile } = props;
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);
  const [revisiones, setRevisiones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seleccionado, setSeleccionado] = useState();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [filterBy, setFilterBy] = useState({
    fecha: "",
    equipo: "",
    operador: "",
  });
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initData = useCallback(async () => {
    try {
      let res;
      // Obtener usuarios
      res = await getUsuarios();
      if (res.status === 200) {
        setUsuarios(res.data);
      } else {
        throw new Error("Error al cargar usuarios");
      }

      if (res.status === 200) {
        setRevisiones(res.data);
      } else {
        throw new Error("Error al cargar planificación");
      }
      let combinedData = [];

      const revisionPromises = [
        getRevisionAutoelevadores(),
        getRevisionLuminaria(),
        getRevisionDosificadoras(),
        getRevisionGeneradores(),
        getRevisionSandvan(),
        getRevisionMiniPala(),
        getRevisionSanders(),
        getRevisionBaseSV(),
      ];

      const revisionResults = await Promise.all(revisionPromises);
      revisionResults.forEach((obj) => {
        if (obj.status === 200) {
          combinedData = combinedData.concat(obj.data);
        }
      });

      setRevisiones(combinedData);
    } catch (error) {
      console.error("Error in initData:", error.message);
      toast({
        status: "error",
        isClosable: true,
        title: error.message,
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const cleanFilter = () => {
    setFilterBy({
      fecha: "",
      equipo: "",
      operador: "",
    });
    setSelectedFilter(null);
  };

  const handleResponsable = (event) => {
    let item = event;

    return item.nombre;
  };

  const filterStyle = (filterName) => ({
    // Aplicar un estilo diferente al filtro seleccionado
    backgroundColor:
      filterName === selectedFilter ? "lightblue" : "transparent",
  });

  const showRevisiones = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return revisiones;
    }

    const { inicio, fin, equipo, operador } = filterBy;

    return revisiones.filter((revision) => {
      const fechaRevision = moment(revision.fechaRevision);
      const cumpleFechaInicio =
        !inicio || fechaRevision.isSameOrAfter(inicio, "day");
      const cumpleFechaFin = !fin || fechaRevision.isSameOrBefore(fin, "day");
      const cumpleEquipo = !equipo.length || equipo === revision.equipoRevision;
      const cumpleOperador =
        !operador.length || operador.includes(revision.nombre);

      return (
        cumpleFechaInicio && cumpleFechaFin && cumpleEquipo && cumpleOperador
      );
    });
  };

  const handleValue = (e) => {
    if (e === true) {
      return (
        <Icon
          as={MdOutlineCheckCircleOutline}
          color="white"
          bg="green"
          borderRadius="50%"
        />
      );
    } else {
      return (
        <Icon
          as={MdOutlineHighlightOff}
          color="white"
          bg="red"
          borderRadius="50%"
        />
      );
    }
  };

  const handleEquipo = (elemento) => {
    if (
      elemento.equipoRevision === "Generador" ||
      elemento.equipoRevision === "Mini Pala" ||
      elemento.equipoRevision === "Luminaria" ||
      elemento.equipoRevision === "BaseSandvan"
    ) {
      return elemento.equipoRevision;
    } else if (elemento.equipoRevision === "Autoelevador") {
      if (elemento.nombreAutoE) {
        return elemento.nombreAutoE;
      } else {
        return "Autoelevador";
      }
    } else if (elemento.equipoRevision === "SandVan") {
      if (elemento.codigoSandVan) {
        return elemento.codigoSandVan;
      } else {
        return "SandVan";
      }
    } else if (elemento.equipoRevision === "Dosificadora") {
      if (elemento.nombreDosificadora) {
        return elemento.nombreDosificadora;
      } else {
        return "Dosificadora";
      }
    }
  };

  const nombresResponsables = revisiones.reduce((nombresUnicos, revision) => {
    let a = usuarios.find((elem) => elem.idUsuario === revision.responsable);
    if (a && !nombresUnicos.includes(a.nombre) && a.nombre !== undefined) {
      nombresUnicos.push(a.nombre);
    }
    return nombresUnicos;
  }, []);

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h={"calc(100vh - 130px)"}>
      <HStack
        w="100%"
        bg="brand.fondos_secundario"
        justifyContent="space-between"
      >
        <HStack pl={5}>
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => navigate(-1)}
              fontSize="24px"
            />
          </Box>
          <Heading
            fontWeight="medium"
            size="xl"
            fontFamily="fonts.title"
            color="brand.gris_primario"
          >
            Revisión entre Turnos
          </Heading>
        </HStack>
        <HStack spacing={2} px={3}>
          {user.idRol !== "userOperaciones" && (
            <DescargaRevision revisiones={showRevisiones()} type="historial" />
          )}
          <Popover ml={2} isLazy>
            <PopoverTrigger>
              <IconButton
                icon={<MdFilterList fontSize="20px" />}
                bg={"brand.fondos_secundarios"}
                borderRadius="50%"
                color={"black"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <Tooltip label="Limpiar filtro">
                  <IconButton
                    icon={<MdRefresh />}
                    onClick={cleanFilter}
                    variant="link"
                    justifySelf={"end"}
                  />
                </Tooltip>
              </PopoverHeader>
              <PopoverBody maxH={"sm"} overflowY={"auto"}>
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Operador
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        {nombresResponsables.map((usuario) => (
                          <HStack key={usuario}>
                            {selectedFilter === usuario && <MdCheck />}

                            <ListItem
                              style={filterStyle(usuario)}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  operador: usuario,
                                }));
                                setSelectedFilter(usuario);
                                showRevisiones();
                              }}
                            >
                              {usuario}
                            </ListItem>
                          </HStack>
                        ))}
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Por Equipo
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        <HStack>
                          {selectedFilter === "autoelevador" && <MdCheck />}

                          <ListItem
                            style={filterStyle("autoelevador")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "Autoelevador",
                              }));
                              showRevisiones();
                              setSelectedFilter("autoelevador");
                            }}
                          >
                            Autoelevador
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "generador" && <MdCheck />}

                          <ListItem
                            style={filterStyle("generador")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "Generador",
                              }));
                              setSelectedFilter("generador");
                              showRevisiones();
                            }}
                          >
                            Generador
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "sander" && <MdCheck />}

                          <ListItem
                            style={filterStyle("sander")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "Sander",
                              }));
                              setSelectedFilter("sander");
                              showRevisiones();
                            }}
                          >
                            Sander
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "sandvan" && <MdCheck />}

                          <ListItem
                            style={filterStyle("sandvan")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "SandVan",
                              }));
                              setSelectedFilter("sandvan");
                              showRevisiones();
                            }}
                          >
                            SandVan
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "basesandvan" && <MdCheck />}

                          <ListItem
                            style={filterStyle("basesandvan")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "BaseSandvan",
                              }));
                              setSelectedFilter("basesandvan");
                              showRevisiones();
                            }}
                          >
                            BaseSandVan
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "luminaria" && <MdCheck />}

                          <ListItem
                            style={filterStyle("luminaria")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "Luminaria",
                              }));
                              setSelectedFilter("luminaria");
                              showRevisiones();
                            }}
                          >
                            Luminarias
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "minipala" && <MdCheck />}

                          <ListItem
                            style={filterStyle("minipala")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "Mini Pala",
                              }));
                              setSelectedFilter("minipala");
                              showRevisiones();
                            }}
                          >
                            Mini Pala
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "dosificadora" && <MdCheck />}

                          <ListItem
                            style={filterStyle("dosificadora")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                equipo: "Dosificadora",
                              }));
                              setSelectedFilter("dosificadora");
                              showRevisiones();
                            }}
                          >
                            Dosifiacadora
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      <HStack>
                        <Text fontWeight={"bold"}>Fecha inicio</Text>
                        <Input
                          type="date"
                          size="sm"
                          w={150}
                          onChange={(e) => {
                            setFilterBy((prevstate) => ({
                              ...prevstate,
                              inicio: e.target.value,
                            }));
                            showRevisiones();
                          }}
                          value={filterBy.inicio}
                        />
                      </HStack>
                    </AccordionButton>
                  </AccordionItem>
                </Accordion>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      <HStack
        w="100%"
        h={"100%"}
        px={3}
        bg="brand.fondos_secundario"
        borderRadius={5}
      >
        <Stack w={"60%"} h="100%" placeSelf="self-start">
          <TableContainer overflowY="auto">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr
                  style={{
                    position: "sticky",
                    top: -5,
                    zIndex: 1,
                    backgroundColor: "#F3F6F9",
                    opacity: 1,
                    marginTop: "-10px",
                  }}
                >
                  <Th></Th>
                  <Th
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() =>
                      (document.querySelector(".hover-icon").style.display =
                        "inline-block")
                    }
                    onMouseLeave={() =>
                      (document.querySelector(".hover-icon").style.display =
                        "none")
                    }
                  >
                    <span
                      className="sortable"
                      onClick={() => {
                        const { sortedData, direction } = sortData(
                          "fechaRevision",
                          revisiones
                        );
                        setSortField("fechaRevision");
                        setSortDirection(direction);
                        setRevisiones(sortedData);
                      }}
                      _hover={<Icon as={MdOutlineImportExport} />}
                    >
                      Fecha y Hora
                      {sortDirection === "asc" &&
                        sortField === "fechaRevision" && <ArrowUpIcon />}
                      {sortDirection === "des" &&
                        sortField === "fechaRevision" && <ArrowDownIcon />}
                    </span>
                    <span className="hover-icon" style={{ display: "none" }}>
                      <MdOutlineImportExport />
                    </span>
                  </Th>
                  <Th
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() =>
                      (document.querySelector(".hover-icon1").style.display =
                        "inline-block")
                    }
                    onMouseLeave={() =>
                      (document.querySelector(".hover-icon1").style.display =
                        "none")
                    }
                  >
                    <span
                      className="sortable"
                      onClick={() => {
                        const { sortedData, direction } = sortData(
                          "equipoRevision",
                          revisiones
                        );
                        setSortField("equipoRevision");
                        setSortDirection(direction);
                        setRevisiones(sortedData);
                      }}
                      _hover={<Icon as={MdOutlineImportExport} />}
                    >
                      Equipo
                      {sortDirection === "asc" &&
                        sortField === "equipoRevision" && <ArrowUpIcon />}
                      {sortDirection === "des" &&
                        sortField === "equipoRevision" && <ArrowDownIcon />}
                    </span>
                    <span className="hover-icon1" style={{ display: "none" }}>
                      <MdOutlineImportExport />
                    </span>
                  </Th>

                  <Th
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() =>
                      (document.querySelector(".hover-icon2").style.display =
                        "inline-block")
                    }
                    onMouseLeave={() =>
                      (document.querySelector(".hover-icon2").style.display =
                        "none")
                    }
                  >
                    <span
                      className="sortable"
                      onClick={() => {
                        const { sortedData, direction } = sortData(
                          "nombre",
                          revisiones
                        );
                        setSortField("nombre");
                        setSortDirection(direction);
                        setRevisiones(sortedData);
                      }}
                      _hover={<Icon as={MdOutlineImportExport} />}
                    >
                      Responsable
                      {sortDirection === "asc" && sortField === "nombre" && (
                        <ArrowUpIcon />
                      )}
                      {sortDirection === "des" && sortField === "nombre" && (
                        <ArrowDownIcon />
                      )}
                    </span>
                    <span className="hover-icon2" style={{ display: "none" }}>
                      <MdOutlineImportExport />
                    </span>
                  </Th>
                </Tr>
              </Thead>
              {loading && (
                <Tbody>
                  <Tr>
                    <Td colSpan={6}>{loadingtable()}</Td>
                  </Tr>
                </Tbody>
              )}
              {!loading && revisiones.length < 1 && (
                <Tbody>
                  <Tr>
                    <Td
                      colSpan={6}
                      textAlign="center"
                      fontWeight="bold"
                      color="brand.gris_primario"
                    >
                      No hay revisiones creadas
                    </Td>
                  </Tr>
                </Tbody>
              )}
              {!loading && revisiones.length > 0 && (
                <Tbody textAlign="center">
                  {showRevisiones().map((revision, index) => (
                    <Tr
                      key={index}
                      onClick={() => {
                        setSeleccionado(revision);
                        onOpen();
                      }}
                    >
                      <Td>
                        <RadioGroup
                          value={
                            (seleccionado?.equipoRevision === "Autoelevador" &&
                              revision.equipoRevision === "Autoelevador" &&
                              seleccionado?.idRevisionAutoE) ||
                            (seleccionado?.equipoRevision === "Generador" &&
                              revision.equipoRevision === "Generador" &&
                              seleccionado?.idRevisionGenerador) ||
                            (seleccionado?.equipoRevision === "Dosificadora" &&
                              revision.equipoRevision === "Dosificadora" &&
                              seleccionado?.idRevisionDosificadora) ||
                            (seleccionado?.equipoRevision === "Mini Pala" &&
                              revision.equipoRevision === "Mini Pala" &&
                              seleccionado?.idRevisionMP) ||
                            (seleccionado?.equipoRevision === "Sander" &&
                              revision.equipoRevision === "Sander" &&
                              seleccionado?.idRevisionSander) ||
                            (seleccionado?.equipoRevision === "Luminaria" &&
                              revision.equipoRevision === "Luminaria" &&
                              seleccionado?.idRevisionLum) ||
                            (seleccionado?.equipoRevision === "BaseSandvan" &&
                              revision.equipoRevision === "BaseSandvan" &&
                              seleccionado?.idRevisionBSV) ||
                            (seleccionado?.equipoRevision === "SandVan" &&
                              revision.equipoRevision === "SandVan" &&
                              seleccionado?.idRevisionSV)
                          }
                        >
                          <Radio
                            value={
                              (revision.equipoRevision === "Autoelevador" &&
                                revision.idRevisionAutoE) ||
                              (revision.equipoRevision === "Generador" &&
                                revision.idRevisionGenerador) ||
                              (revision.equipoRevision === "Dosificadora" &&
                                revision.idRevisionDosificadora) ||
                              (revision.equipoRevision === "Mini Pala" &&
                                revision.idRevisionMP) ||
                              (revision.equipoRevision === "Sander" &&
                                revision.idRevisionSander) ||
                              (revision.equipoRevision === "Luminaria" &&
                                revision.idRevisionLum) ||
                              (revision.equipoRevision === "BaseSandvan" &&
                                revision.idRevisionBSV) ||
                              (revision.equipoRevision === "SandVan" &&
                                revision.idRevisionSV)
                            }
                            border="1px solid black"
                          />
                        </RadioGroup>
                      </Td>
                      <Td>
                        {moment
                          .utc(revision.fechaRevision)
                          .format("DD-MM-YY HH:mm")}
                      </Td>
                      <Td>{revision.equipoRevision}</Td>
                      <Td>{revision.nombre}</Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Stack>
        {seleccionado && mobile && (
          <Card
            bg="brand.layout"
            border="none"
            w={"40%"}
            h="100%"
            boxShadow="none"
            pr={2}
            pb={2}
          >
            <CardHeader pb="unset" color="brand.azul_text">
              <HStack>
                <Text fontWeight="semibold" fontSize="24px">
                  Reporte{" "}
                  {moment
                    .utc(seleccionado.fechaRevision)
                    .format("DD-MM-YY HH:mm")}
                </Text>
              </HStack>
              <FormControl>
                <HStack justifyContent="space-between">
                  <FormLabel fontWeight="semibold">
                    Operador Responsable
                  </FormLabel>
                  <Text textTransform="uppercase" fontWeight="semibold">
                    {handleResponsable(seleccionado)}
                  </Text>
                </HStack>
              </FormControl>
            </CardHeader>
            <Divider pt={1} color="#EBEAEA" />
            {seleccionado.equipoRevision === "Generador" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>

                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel de refrigerante
                    </FormLabel>
                    {handleValue(seleccionado.nivelRefrigeranteRG)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Nivel de aceite de motor
                    </FormLabel>
                    {handleValue(seleccionado.nivelAceiteRG)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel de combustible
                    </FormLabel>
                    {handleValue(seleccionado.nivelCombustibleRG)}
                  </HStack>
                </FormControl>
                <Divider />
                <VStack py={1}>
                  <Text>Parámetros de funcionamiento:</Text>
                  <FormControl>
                    <HStack justifyContent="space-between" w="100%">
                      <FormLabel fontWeight="semibold">Horometro</FormLabel>

                      {handleValue(seleccionado.horometroRG)}
                    </HStack>
                  </FormControl>
                  <FormControl>
                    <HStack justifyContent="space-between" w="100%">
                      <FormLabel fontWeight="semibold">Voltaje</FormLabel>

                      {handleValue(seleccionado.voltajeRG)}
                    </HStack>
                  </FormControl>
                  <FormControl>
                    <HStack justifyContent="space-between" w="100%">
                      <FormLabel fontWeight="semibold">Frecuencia</FormLabel>

                      {handleValue(seleccionado.frecuenciaRG)}
                    </HStack>
                  </FormControl>
                  <FormControl>
                    <HStack justifyContent="space-between" w="100%">
                      <FormLabel fontWeight="semibold">
                        Presión de Aceite
                      </FormLabel>

                      {handleValue(seleccionado.presionAceiteRG)}
                    </HStack>
                  </FormControl>
                  <FormControl>
                    <HStack justifyContent="space-between" w="100%">
                      <FormLabel fontWeight="semibold" placeSelf="start">
                        Temperatura de motor
                      </FormLabel>

                      {handleValue(seleccionado.temperaturaRG)}
                    </HStack>
                  </FormControl>
                </VStack>
                <Divider />
                <FormControl>
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Condiciones de filtro de aire
                    </FormLabel>
                    {handleValue(seleccionado.filtroAireRG)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Fallas en Display
                    </FormLabel>
                    {handleValue(seleccionado.fallaDisplayRG)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Condición de correa
                    </FormLabel>
                    {handleValue(seleccionado.correaRG)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Perdidas en mangueras
                    </FormLabel>
                    {handleValue(seleccionado.manguerasRG)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cargador de baterías
                    </FormLabel>
                    {handleValue(seleccionado.cargadorBateriasRG)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Bornes sulfatados o flojos
                    </FormLabel>
                    {handleValue(seleccionado.bornesRG)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Disyuntor
                    </FormLabel>
                    {handleValue(seleccionado.disyuntorRG)}
                  </HStack>
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "Autoelevador" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>

                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Documentos
                    </FormLabel>

                    {handleValue(seleccionado.documentacionAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Chasis
                    </FormLabel>
                    <Box>{handleValue(seleccionado.cabinaAutoE)}</Box>
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Limpiaparabrisas
                    </FormLabel>

                    {handleValue(seleccionado.limpiaparabrisasAutoE)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Luces, espejos y retrovisores
                    </FormLabel>
                    {handleValue(seleccionado.lucesAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Asiento (sensor, trabas y estado)
                    </FormLabel>
                    {handleValue(seleccionado.asientoAutoE)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Cinturón de Seguridad
                    </FormLabel>

                    {handleValue(seleccionado.cinturonSeguridadAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Aire acondicionado / Calefacción
                    </FormLabel>

                    {handleValue(seleccionado.aireAcondicionadoAutoE)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Alarmas de alertas
                    </FormLabel>
                    {handleValue(seleccionado.alarmasAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Bocina / Alarma de retroceso
                    </FormLabel>
                    {handleValue(seleccionado.bocinaAutoE)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Instrumental / Comandos
                    </FormLabel>
                    {handleValue(seleccionado.instrumentalAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Estructura / Chasis
                    </FormLabel>
                    {handleValue(seleccionado.estructuraAutoE)}
                  </HStack>
                </FormControl>
                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Engrase</FormLabel>
                    {handleValue(seleccionado.engraseAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Protecciones electricas <br />
                      (cortacorrientes y pare de emergencia)
                    </FormLabel>
                    {handleValue(seleccionado.proteccionesAutoE)}
                  </HStack>
                </FormControl>

                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Sistema eléctrico / Baterías
                    </FormLabel>

                    {handleValue(seleccionado.sistemaElectricoAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Motor
                    </FormLabel>
                    {handleValue(seleccionado.motorAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel de fluidos
                    </FormLabel>
                    {handleValue(seleccionado.nivelAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Sistema hidraulico
                    </FormLabel>

                    {handleValue(seleccionado.sistemaHidraulicoAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Transmisión
                    </FormLabel>
                    {handleValue(seleccionado.transmisionAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tapa de tanque de combustible
                    </FormLabel>
                    {handleValue(seleccionado.tapaAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Diferencial / Cadenas
                    </FormLabel>
                    {handleValue(seleccionado.diferencialAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Sistema de dirección
                    </FormLabel>

                    {handleValue(seleccionado.sistemaDireccionAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Bloqueos
                    </FormLabel>
                    {handleValue(seleccionado.bloqueosAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Fin de carrera</FormLabel>
                    {handleValue(seleccionado.finCarreraAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Limitador de carga/vuelco
                    </FormLabel>
                    {handleValue(seleccionado.limitadorAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Ajuste de tuercas de contrapeso
                    </FormLabel>
                    {handleValue(seleccionado.ajusteTuercasAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Pernos</FormLabel>
                    {handleValue(seleccionado.pernosAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Alemites
                    </FormLabel>
                    {handleValue(seleccionado.alemitesAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cilindros hidráulicos
                    </FormLabel>

                    {handleValue(seleccionado.cilindrosHidraulicosAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Ruedas (estado, presión, check
                      <br /> points de tuercas, piedras)
                    </FormLabel>
                    {handleValue(seleccionado.ruedasAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Uñas, rotulas y rodillos
                    </FormLabel>
                    {handleValue(seleccionado.urrAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Pérdidas de fluidos
                    </FormLabel>
                    {handleValue(seleccionado.perdidasFluidoAutoE)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Mangueras, correas
                    </FormLabel>
                    {handleValue(seleccionado.manguerasAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Válvulas de seguridad
                    </FormLabel>
                    {handleValue(seleccionado.valvulasAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Frenos
                    </FormLabel>
                    {handleValue(seleccionado.frenosAutoE)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Extintor (carga, vencimiento, clavija)
                    </FormLabel>
                    {handleValue(seleccionado.extintorAutoE)}
                  </HStack>
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "Mini Pala" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>

                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Documentos
                    </FormLabel>
                    {handleValue(seleccionado.documentacionMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cabina, vidrio, jaula (interior y exterior)
                    </FormLabel>
                    {handleValue(seleccionado.cabinaMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Instrumental / Comandos
                    </FormLabel>
                    {handleValue(seleccionado.instrumentalMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Luces, espajos y retrovisores
                    </FormLabel>
                    {handleValue(seleccionado.lucesMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Asiento (sensor, trabas y estado)
                    </FormLabel>
                    {handleValue(seleccionado.asientoMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Cinturón de Seguridad
                    </FormLabel>
                    {handleValue(seleccionado.cinturonMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Pernos</FormLabel>
                    {handleValue(seleccionado.pernosMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Alarmas de alertas
                    </FormLabel>
                    {handleValue(seleccionado.alarmasMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Bocina / Alarma de retroceso
                    </FormLabel>
                    {handleValue(seleccionado.bocinaMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tapa de tanque de combustible
                    </FormLabel>
                    {handleValue(seleccionado.tapaTanqueMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Válvulas de seguridad
                    </FormLabel>
                    {handleValue(seleccionado.valvulasMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Sistema eléctrico / Baterías
                    </FormLabel>
                    {handleValue(seleccionado.sistemaElectricoMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Motor
                    </FormLabel>
                    {handleValue(seleccionado.motorMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel de fluidos
                    </FormLabel>
                    {handleValue(seleccionado.nivelFluidosMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Sistema hidraulico
                    </FormLabel>
                    {handleValue(seleccionado.sistemaHidraulicoMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <VStack py={1}>
                  <FormControl alignSelf="start">
                    <HStack justifyContent="space-between" py={1}>
                      <FormLabel fontWeight="semibold" placeSelf="start">
                        Transmisión
                      </FormLabel>
                      {handleValue(seleccionado.transmisionMP)}
                    </HStack>
                  </FormControl>
                  <FormControl alignSelf="start">
                    <HStack justifyContent="space-between" py={1}>
                      <FormLabel fontWeight="semibold">Engrase</FormLabel>
                      {handleValue(seleccionado.engraseMP)}
                    </HStack>
                  </FormControl>
                  <FormControl alignSelf="start">
                    <HStack justifyContent="space-between" py={1}>
                      <FormLabel fontWeight="semibold" placeSelf="start">
                        Sistema de dirección
                      </FormLabel>
                      {handleValue(seleccionado.sistemaDireccionMP)}
                    </HStack>
                  </FormControl>
                </VStack>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Bloqueos (parking, baranda de seguridad, <br />
                      botón de accionamiento)
                    </FormLabel>
                    {handleValue(seleccionado.bloqueosMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cilindros hidráulicos
                    </FormLabel>
                    {handleValue(seleccionado.cilindrosMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Arrestallamas</FormLabel>
                    {handleValue(seleccionado.arrestallamasMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Ruedas (estado, presión, check points
                      <br /> de tuercas, piedras) Oruga
                    </FormLabel>
                    {handleValue(seleccionado.ruedasMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Estructura / Chasis
                    </FormLabel>
                    {handleValue(seleccionado.estructuraMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Alemites
                    </FormLabel>
                    {handleValue(seleccionado.alemitesMP)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Mangueras, correas
                    </FormLabel>
                    {handleValue(seleccionado.manguerasMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Pérdidas de fluidos
                    </FormLabel>
                    {handleValue(seleccionado.perdidasFluidoMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Balde
                    </FormLabel>
                    {handleValue(seleccionado.baldeMP)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Extintor (carga, vencimiento, clavija)
                    </FormLabel>
                    {handleValue(seleccionado.extintorMP)}
                  </HStack>
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "Dosificadora" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique la alineación de la banda
                    </FormLabel>
                    {handleValue(seleccionado.alineacionBandaDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verificar si algun rodillo de
                      <br /> las estaciones presenta desgaste
                    </FormLabel>
                    {handleValue(seleccionado.desgasteRodilloDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique el desgaste de la cuchilla
                      <br /> en el rolo tractor
                    </FormLabel>

                    {handleValue(seleccionado.desgasteRoloTractorDosi)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique el estado de la banda
                    </FormLabel>
                    {handleValue(seleccionado.estadoBandaDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique el estado del faldon de
                      <br /> hule y ajustar de ser necesario
                    </FormLabel>
                    {handleValue(seleccionado.estadoFaldonHuleDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Limpieza de rodillos de las estaciones,
                      <br /> si presenta acumulacion de material
                    </FormLabel>

                    {handleValue(seleccionado.materialEnRodilloDosi)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique el nivel de aceite hidraulico
                    </FormLabel>
                    {handleValue(seleccionado.nvlAceiteHidraDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verificar que los rodillos de las estaciones
                      <br /> giren libremente
                    </FormLabel>
                    {handleValue(seleccionado.rodillosDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verificar que haya separacion entre el <br />
                      protector de retorno y el rodillo de retorno
                    </FormLabel>

                    {handleValue(seleccionado.separacionProtectorRodilloDosi)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique la tensión de la banda
                    </FormLabel>
                    {handleValue(seleccionado.tensionBandaDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Inspección del reductor del Conjunto de <br /> transmision
                      para radiado (zona de cubierta)
                    </FormLabel>
                    {handleValue(seleccionado.transmisionDosi)}
                  </HStack>
                </FormControl>

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verifique la unión de banda
                    </FormLabel>
                    {handleValue(seleccionado.unionBandaDosi)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <Stack py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Observaciones
                    </FormLabel>
                    <Text>{seleccionado.observacionesDosi}</Text>
                  </Stack>
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "BaseSandvan" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Luminaria externa
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero externo
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tomas electricas externas
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Indicadores de voltaje externo
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero interno P1
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero interno P2
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero interno P3
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero interno P4
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verificar nivel de aceite en compresor de aire
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verificar nivel de combustible en compresor de aire
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Verificar estado de la corre en compresor de aire
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Funcionamiento de sistema de aire en compresor de aire
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Funcionamiento de llaves de paso en comrpesor de aire
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "SandVan" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Aire acondicionado
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Luminarias internas
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tomas internas
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Presurizador
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Matafuego
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Sillas
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Monitores
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Notebook de sander
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Notebook de fractura
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero de comando sander A
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero de comando sander B
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Sistema de camaras Sander A
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Sistema de camaras sander B
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Reflectores externos
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Techo rebatible
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Limpieza de filtro pesurizador
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Manga de viento
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Antenas sistema de camara
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <Text>Wetsand</Text>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Tablero de comando WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Sistema camaras Dosificadora
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P1 SA WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P2 SA WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P3 SA WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P4 SA WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P1 SB WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P2 SB WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P3 SB WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Activacion de vibradores P4 SB WET
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "Sander" && (
              <CardBody overflow="auto">
                <Heading size="md" py={2}>
                  Sander principal
                </Heading>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">Sander</FormLabel>
                    <Text>{seleccionado.codigoSanderA}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      QR Code A1
                    </FormLabel>
                    <Text>{seleccionado.qrCodeA1}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Power Pack A
                    </FormLabel>
                    <Text>{seleccionado.powerPackA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Horas Motor A
                    </FormLabel>
                    <Text>{seleccionado.horasMotorA}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Combustible: Nivel
                    </FormLabel>
                    <Text>{seleccionado.nivelCombustibleA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Perdidas Combustible
                    </FormLabel>
                    {handleValue(seleccionado.perdidasCombustibleA)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Presion Aceite</FormLabel>
                    <Text>{seleccionado.presionAceiteA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel Aceite
                    </FormLabel>
                    {handleValue(seleccionado.nivelAceiteA)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Temperatura Refrigerante
                    </FormLabel>
                    <Text>{seleccionado.tempRefrigeranteA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Perdidas Refrigerante
                    </FormLabel>
                    {handleValue(seleccionado.perdidasRefrigeranteA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel Refrigerante
                    </FormLabel>
                    {handleValue(seleccionado.nivelRefrigeranteA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Refrigerante: Sin pérdidas?
                    </FormLabel>
                    {handleValue(seleccionado.refrigerantePerdidaA)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Eléctrico: Estado borneras
                    </FormLabel>
                    {handleValue(seleccionado.estadoBorneasA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Limpieza de filtro primario de aire
                    </FormLabel>
                    {handleValue(seleccionado.limpiezaFiltroAireA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Limpieza</FormLabel>
                    {handleValue(seleccionado.limpizaA)}
                  </HStack>
                </FormControl>
                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      QR Code A.2
                    </FormLabel>
                    <Text>{seleccionado.qrCodeA2A}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Banda Transportadora
                    </FormLabel>
                    {handleValue(seleccionado.bandaTransportadoraA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Alineación de Banda
                    </FormLabel>
                    {handleValue(seleccionado.alineacionBandaA)}
                  </HStack>
                </FormControl>

                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Rolos de Retorno
                    </FormLabel>
                    {handleValue(seleccionado.rolosRetornoA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Rolos ventilados
                    </FormLabel>
                    {handleValue(seleccionado.rolosVentiladosA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Rolos Sdrive
                    </FormLabel>
                    {handleValue(seleccionado.rolosSdriveA)}
                  </HStack>
                </FormControl>

                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Unión de Banda</FormLabel>
                    {handleValue(seleccionado.unionBandaA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cadena de Transmisión de cinta principal
                    </FormLabel>
                    {handleValue(seleccionado.ctcpA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Acoplamiento de Cinta Frontal
                    </FormLabel>
                    {handleValue(seleccionado.acoplamientoCFA)}
                  </HStack>
                </FormControl>

                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Anclaje placas de celdas
                    </FormLabel>
                    {handleValue(seleccionado.anclajePlacaCeldasA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Lubricación de rodamientos
                    </FormLabel>

                    {handleValue(seleccionado.lubricacionRodamientosA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Lubricación de cadena
                    </FormLabel>
                    {handleValue(seleccionado.lubricacionCadenaA)}
                  </HStack>
                </FormControl>

                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">QR Code A.3</FormLabel>
                    <Text>{seleccionado.qrCodeA3A}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Horas Circuito Hidráulico (Q.Variable)
                    </FormLabel>
                    <Text>{seleccionado.hsCircuitoHidraA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Aceite Hidráulico: Temperatura
                    </FormLabel>
                    <Text>{seleccionado.tempAceiteHidraA}</Text>
                  </HStack>
                </FormControl>

                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Aceite Hidráulico: Nivel
                    </FormLabel>
                    {handleValue(seleccionado.nivelAceiteHidraA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Aceite Hidráulico: Sin Pérdidas?
                    </FormLabel>
                    {handleValue(seleccionado.aceiteHidraPerdidaA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Movimiento de Carros
                    </FormLabel>
                    {handleValue(seleccionado.movimientoCarrosA)}
                  </HStack>
                </FormControl>

                <Divider />

                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Presión de retorno de bomba de engranajes
                    </FormLabel>
                    <Text>{seleccionado.presionRetonoBEA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Presión de StandBy de bomba de pistones
                    </FormLabel>
                    <Text>{seleccionado.presionSantByBPA}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Presión de trabajo de bomba de pistones
                    </FormLabel>
                    <Text>{seleccionado.presionTrabajoBPA}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Tableros cerrados
                    </FormLabel>
                    {handleValue(seleccionado.tablerosCerradosA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cable de comando
                    </FormLabel>
                    {handleValue(seleccionado.cableComandoA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Calibración de balanzas
                    </FormLabel>
                    {handleValue(seleccionado.calibracionBalanzaA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Funcionamiento Semáforos y Luces
                    </FormLabel>
                    {handleValue(seleccionado.semaforoLucesA)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Observaciones y Comentarios
                  </FormLabel>
                  <Text>{seleccionado.observacionesSA}</Text>
                </FormControl>
                <Divider />
                <Heading size="md" py={2}>
                  Sander BackUp
                </Heading>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text>{seleccionado.codigoSanderB}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      QR Code B1
                    </FormLabel>
                    <Text>{seleccionado.qrCodeB1}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Power Pack B
                    </FormLabel>
                    <Text>{seleccionado.powerPackB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Horas Motor B
                    </FormLabel>
                    <Text>{seleccionado.horasMotorB}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Combustible: Nivel
                    </FormLabel>
                    <Text>{seleccionado.nivelCombustibleB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Perdidas Combustible
                    </FormLabel>
                    {handleValue(seleccionado.perdidasCombustibleB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Presion Aceite</FormLabel>
                    <Text>{seleccionado.presionAceiteB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel Aceite
                    </FormLabel>
                    {handleValue(seleccionado.nivelAceiteB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Temperatura Refrigerante
                    </FormLabel>
                    <Text>{seleccionado.tempRefrigeranteB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Perdidas Refrigerante
                    </FormLabel>

                    {handleValue(seleccionado.perdidasRefrigeranteB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel Refrigerante
                    </FormLabel>
                    {handleValue(seleccionado.nivelRefrigeranteB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Refrigerante: Sin pérdidas?
                    </FormLabel>
                    {handleValue(seleccionado.refrigerantePerdidaB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">
                      Eléctrico: Estado borneras
                    </FormLabel>
                    {handleValue(seleccionado.estadoBorneasB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Limpieza de filtro primario de aire
                    </FormLabel>
                    {handleValue(seleccionado.limpiezaFiltroAireB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Limpieza</FormLabel>
                    {handleValue(seleccionado.limpizaB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      QR Code B.2
                    </FormLabel>
                    <Text>{seleccionado.qrCodeB2}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Banda Transportadora
                    </FormLabel>
                    {handleValue(seleccionado.bandaTransportadoraB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Alineación de Banda
                    </FormLabel>
                    {handleValue(seleccionado.alineacionBandaB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Rolos de Retorno
                    </FormLabel>
                    {handleValue(seleccionado.rolosRetornoB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Rolos ventilados
                    </FormLabel>
                    {handleValue(seleccionado.rolosVentiladosB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Rolos Sdrive
                    </FormLabel>
                    {handleValue(seleccionado.rolosSdriveB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">Unión de Banda</FormLabel>
                    {handleValue(seleccionado.unionBandaB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cadena de Transmisión de cinta principal
                    </FormLabel>
                    {handleValue(seleccionado.ctcpB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Acoplamiento de Cinta Frontal
                    </FormLabel>
                    {handleValue(seleccionado.acoplamientoCFB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Anclaje placas de celdas
                    </FormLabel>
                    {handleValue(seleccionado.anclajePlacaCeldasB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Lubricación de rodamientos
                    </FormLabel>

                    {handleValue(seleccionado.lubricacionRodamientosB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Lubricación de cadena
                    </FormLabel>
                    {handleValue(seleccionado.lubricacionCadenaB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">QR Code B.3</FormLabel>
                    <Text>{seleccionado.qrCodeA3B}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Horas Circuito Hidráulico (Q.Variable)
                    </FormLabel>
                    <Text>{seleccionado.hsCircuitoHidraB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Aceite Hidráulico: Temperatura
                    </FormLabel>
                    <Text>{seleccionado.tempAceiteHidraB}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Aceite Hidráulico: Nivel
                    </FormLabel>
                    {handleValue(seleccionado.nivelAceiteHidraB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Aceite Hidráulico: Sin Pérdidas?
                    </FormLabel>
                    {handleValue(seleccionado.aceiteHidraPerdidaB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Movimiento de Carros
                    </FormLabel>
                    {handleValue(seleccionado.movimientoCarrosB)}
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Presión de retorno de bomba de engranajes
                    </FormLabel>
                    <Text>{seleccionado.presionRetonoBEB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Presión de StandBy de bomba de pistones
                    </FormLabel>
                    <Text>{seleccionado.presionSantByBPB}</Text>
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Presión de trabajo de bomba de pistones
                    </FormLabel>
                    <Text>{seleccionado.presionTrabajoBPB}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold">
                      Tableros cerrados
                    </FormLabel>
                    {handleValue(seleccionado.tablerosCerradosA)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cable de comando
                    </FormLabel>
                    {handleValue(seleccionado.cableComandoB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Calibración de balanzas
                    </FormLabel>
                    {handleValue(seleccionado.calibracionBalanzaB)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Funcionamiento Semáforos y Luces
                    </FormLabel>
                    {handleValue(seleccionado.semaforoLucesB)}
                  </HStack>
                </FormControl>
                <Divider />
                <HStack py={1}>
                  <FormControl alignSelf="start">
                    <FormLabel fontWeight="semibold">
                      Observaciones y Comentarios
                    </FormLabel>
                    {seleccionado.observacionesSB}
                  </FormControl>
                </HStack>
                <Divider />
                <HStack py={1}>
                  <FormControl alignSelf="start">
                    <FormLabel fontWeight="semibold">
                      Observaciones y Comentarios general
                    </FormLabel>
                    {seleccionado.observacionesGral}
                  </FormControl>
                </HStack>
                <Text>Wetsand</Text>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de resortes P1
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de resortes P2
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de resortes P3
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de resortes P4
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de contactores P1
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de contactores P2
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de contactores P3
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar estado de contactores P4
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar cableado de contactor P1
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar cableado de contactor P2
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar cableado de contactor P3
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar cableado de contactor P4
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar conectores y mangueras de aire - contactor P1
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar conectores y mangueras de aire - contactor P2
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar conectores y mangueras de aire - contactor P3
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Verificar conectores y mangueras de aire - contactor P4
                  </FormLabel>
                  {seleccionado.observacionesGral}
                </FormControl>
              </CardBody>
            )}
            {seleccionado.equipoRevision === "Luminaria" && (
              <CardBody overflow="auto">
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold">Equipo</FormLabel>
                    <Text fontSize="sm">{handleEquipo(seleccionado)}</Text>
                  </HStack>
                </FormControl>
                <Divider />
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Estructura gral. del equipo
                    </FormLabel>
                    {handleValue(seleccionado.estructuraLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Torre de iluminacion
                    </FormLabel>
                    {handleValue(seleccionado.torreLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Lamparas / Focos
                    </FormLabel>
                    {handleValue(seleccionado.lamparaFocoLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Motor
                    </FormLabel>
                    {handleValue(seleccionado.motorLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel de aceite
                    </FormLabel>
                    {handleValue(seleccionado.nivelAceiteLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Nivel de refrigerante
                    </FormLabel>
                    {handleValue(seleccionado.nivelRefrigeranteLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Estado de correa
                    </FormLabel>
                    {handleValue(seleccionado.estadoCorreaLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Parada de emergencia
                    </FormLabel>
                    {handleValue(seleccionado.paradaLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Batería / Alternador
                    </FormLabel>
                    {handleValue(seleccionado.bateriaAlterLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Puesta a tierra
                    </FormLabel>
                    {handleValue(seleccionado.puestaLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Bandeja antiderrame
                    </FormLabel>
                    {handleValue(seleccionado.bandejaLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Arrestallama
                    </FormLabel>
                    {handleValue(seleccionado.arrestallamaLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Cable de conexión
                    </FormLabel>
                    {handleValue(seleccionado.cableLum)}
                  </HStack>
                </FormControl>
                <FormControl alignSelf="start">
                  <HStack justifyContent="space-between" py={1}>
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Observaciones
                    </FormLabel>
                    {seleccionado.observacionesLum}
                  </HStack>
                </FormControl>
              </CardBody>
            )}
          </Card>
        )}
        {seleccionado && !mobile && (
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <Card
                  bg="brand.layout"
                  maxH="lg"
                  border="none"
                  boxShadow="none"
                  pr={2}
                >
                  <CardHeader pb="unset" color="brand.azul_text">
                    <HStack>
                      <Text fontWeight="semibold" fontSize="24px">
                        Reporte{" "}
                        {moment(seleccionado.fechaRevision).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </Text>
                    </HStack>
                    <FormControl>
                      <HStack justifyContent="space-between">
                        <FormLabel fontWeight="semibold">
                          Operador Responsable
                        </FormLabel>
                        <Text textTransform="uppercase" fontWeight="semibold">
                          {handleResponsable(seleccionado)}
                        </Text>
                      </HStack>
                    </FormControl>
                  </CardHeader>
                  <Divider pt={1} color="#EBEAEA" />
                  {seleccionado.equipoRevision === "Generador" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        <Text fontSize="sm">{seleccionado.equipoRevision}</Text>
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel de refrigerante
                          </FormLabel>
                          {handleValue(seleccionado.nivelRefrigeranteRG)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Nivel de aceite de motor
                          </FormLabel>
                          {handleValue(seleccionado.nivelAceiteRG)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel de combustible
                          </FormLabel>
                          {handleValue(seleccionado.nivelCombustibleRG)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <VStack py={1}>
                        <Text>Parámetros de funcionamiento:</Text>
                        <FormControl>
                          <HStack justifyContent="space-between" w="100%">
                            <FormLabel fontWeight="semibold">
                              Horometro
                            </FormLabel>

                            {handleValue(seleccionado.horometroRG)}
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack justifyContent="space-between" w="100%">
                            <FormLabel fontWeight="semibold">Voltaje</FormLabel>

                            {handleValue(seleccionado.voltajeRG)}
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack justifyContent="space-between" w="100%">
                            <FormLabel fontWeight="semibold">
                              Frecuencia
                            </FormLabel>

                            {handleValue(seleccionado.frecuenciaRG)}
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack justifyContent="space-between" w="100%">
                            <FormLabel fontWeight="semibold">
                              Presión de Aceite
                            </FormLabel>

                            {handleValue(seleccionado.presionAceiteRG)}
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack justifyContent="space-between" w="100%">
                            <FormLabel fontWeight="semibold" placeSelf="start">
                              Temperatura de motor
                            </FormLabel>

                            {handleValue(seleccionado.temperaturaRG)}
                          </HStack>
                        </FormControl>
                      </VStack>
                      <Divider />
                      <FormControl>
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Condiciones de filtro de aire
                          </FormLabel>
                          {handleValue(seleccionado.filtroAireRG)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Fallas en Display
                          </FormLabel>
                          {handleValue(seleccionado.fallaDisplayRG)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Condición de correa
                          </FormLabel>
                          {handleValue(seleccionado.correaRG)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Perdidas en mangueras
                          </FormLabel>
                          {handleValue(seleccionado.manguerasRG)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cargador de baterías
                          </FormLabel>
                          {handleValue(seleccionado.cargadorBateriasRG)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Bornes sulfatados o flojos
                          </FormLabel>
                          {handleValue(seleccionado.bornesRG)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Disyuntor
                          </FormLabel>
                          {handleValue(seleccionado.disyuntorRG)}
                        </HStack>
                      </FormControl>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "Autoelevador" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        <Text fontSize="sm">{seleccionado.equipoRevision}</Text>
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Documentos
                          </FormLabel>

                          {handleValue(seleccionado.documentacionAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Chasis
                          </FormLabel>
                          <Box>{handleValue(seleccionado.cabinaAutoE)}</Box>
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Limpiaparabrisas
                          </FormLabel>

                          {handleValue(seleccionado.limpiaparabrisasAutoE)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Luces, espajos y retrovisores
                          </FormLabel>
                          {handleValue(seleccionado.lucesAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Asiento (sensor, trabas y estado)
                          </FormLabel>
                          {handleValue(seleccionado.asientoAutoE)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Cinturón de Seguridad
                          </FormLabel>

                          {handleValue(seleccionado.cinturonSeguridadAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Aire acondicionado / Calefacción
                          </FormLabel>

                          {handleValue(seleccionado.aireAcondicionadoAutoE)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Alarmas de alertas
                          </FormLabel>
                          {handleValue(seleccionado.alarmasAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Bocina / Alarma de retroceso
                          </FormLabel>
                          {handleValue(seleccionado.bocinaAutoE)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Instrumental / Comandos
                          </FormLabel>
                          {handleValue(seleccionado.instrumentalAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Estructura / Chasis
                          </FormLabel>
                          {handleValue(seleccionado.estructuraAutoE)}
                        </HStack>
                      </FormControl>
                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">Engrase</FormLabel>
                          {handleValue(seleccionado.engraseAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Protecciones electricas <br />
                            (cortacorrientes y pare de emergencia)
                          </FormLabel>
                          {handleValue(seleccionado.proteccionesAutoE)}
                        </HStack>
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Sistema electrico / Baterías
                          </FormLabel>

                          {handleValue(seleccionado.sistemaElectricoAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Motor
                          </FormLabel>
                          {handleValue(seleccionado.motorAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel de fluidos
                          </FormLabel>
                          {handleValue(seleccionado.nivelAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Sistema hidraulico
                          </FormLabel>

                          {handleValue(seleccionado.sistemaHidraulicoAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Transmision
                          </FormLabel>
                          {handleValue(seleccionado.transmisionAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tapa de tanque de combustible
                          </FormLabel>
                          {handleValue(seleccionado.tapaAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Diferencial / Cadenas
                          </FormLabel>
                          {handleValue(seleccionado.diferencialAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sistema de dirección
                          </FormLabel>

                          {handleValue(seleccionado.sistemaDireccionAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Bloqueos
                          </FormLabel>
                          {handleValue(seleccionado.bloqueosAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Fin de carrera
                          </FormLabel>
                          {handleValue(seleccionado.finCarreraAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Limitador de carga/vuelco
                          </FormLabel>
                          {handleValue(seleccionado.limitadorAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Ajuste de tuercas de contrapeso
                          </FormLabel>
                          {handleValue(seleccionado.ajusteTuercasAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">Pernos</FormLabel>
                          {handleValue(seleccionado.pernosAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Alemites
                          </FormLabel>
                          {handleValue(seleccionado.alemitesAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cilindros hidráulicos
                          </FormLabel>

                          {handleValue(seleccionado.cilindrosHidraulicosAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Ruedas (estado, presión, check
                            <br /> points de tuercas, piedras)
                          </FormLabel>
                          {handleValue(seleccionado.ruedasAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Uñas, rotulas y rodillos
                          </FormLabel>
                          {handleValue(seleccionado.urrAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Pérdidas de fluidos
                          </FormLabel>
                          {handleValue(seleccionado.perdidasFluidoAutoE)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Mangueras, correas
                          </FormLabel>
                          {handleValue(seleccionado.manguerasAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Válvulas de seguridad
                          </FormLabel>
                          {handleValue(seleccionado.valvulasAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Frenos
                          </FormLabel>
                          {handleValue(seleccionado.frenosAutoE)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Extintor (carga, vencimiento, clavija)
                          </FormLabel>
                          {handleValue(seleccionado.extintorAutoE)}
                        </HStack>
                      </FormControl>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "Mini Pala" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        {seleccionado.equipoRevision}
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Documentos
                          </FormLabel>
                          {handleValue(seleccionado.documentacionMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cabina, vidrio, jaula (interior y exterior)
                          </FormLabel>
                          {handleValue(seleccionado.cabinaMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Instrumental / Comandos
                          </FormLabel>
                          {handleValue(seleccionado.instrumentalMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Luces, espajos y retrovisores
                          </FormLabel>
                          {handleValue(seleccionado.lucesMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Asiento (sensor, trabas y estado)
                          </FormLabel>
                          {handleValue(seleccionado.asientoMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Cinturón de Seguridad
                          </FormLabel>
                          {handleValue(seleccionado.cinturonMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">Pernos</FormLabel>
                          {handleValue(seleccionado.pernosMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Alarmas de alertas
                          </FormLabel>
                          {handleValue(seleccionado.alarmasMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Bocina / Alarma de retroceso
                          </FormLabel>
                          {handleValue(seleccionado.bocinaMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tapa de tanque de combustible
                          </FormLabel>
                          {handleValue(seleccionado.tapaTanqueMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Válvulas de seguridad
                          </FormLabel>
                          {handleValue(seleccionado.valvulasMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Sistema electrico / Baterías
                          </FormLabel>
                          {handleValue(seleccionado.sistemaElectricoMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Motor
                          </FormLabel>
                          {handleValue(seleccionado.motorMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel de fluidos
                          </FormLabel>
                          {handleValue(seleccionado.nivelFluidosMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Sistema hidraulico
                          </FormLabel>
                          {handleValue(seleccionado.sistemaHidraulicoMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <VStack py={1}>
                        <FormControl alignSelf="start">
                          <HStack justifyContent="space-between" py={1}>
                            <FormLabel fontWeight="semibold" placeSelf="start">
                              Transmision
                            </FormLabel>
                            {handleValue(seleccionado.transmisionMP)}
                          </HStack>
                        </FormControl>
                        <FormControl alignSelf="start">
                          <HStack justifyContent="space-between" py={1}>
                            <FormLabel fontWeight="semibold">Engrase</FormLabel>
                            {handleValue(seleccionado.engraseMP)}
                          </HStack>
                        </FormControl>
                        <FormControl alignSelf="start">
                          <HStack justifyContent="space-between" py={1}>
                            <FormLabel fontWeight="semibold" placeSelf="start">
                              Sistema de dirección
                            </FormLabel>
                            {handleValue(seleccionado.sistemaDireccionMP)}
                          </HStack>
                        </FormControl>
                      </VStack>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Bloqueos (parking, baranda de seguridad, <br />
                            botón de accionamiento)
                          </FormLabel>
                          {handleValue(seleccionado.bloqueosMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cilindros hidráulicos
                          </FormLabel>
                          {handleValue(seleccionado.cilindrosMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Arrestallamas
                          </FormLabel>
                          {handleValue(seleccionado.arrestallamasMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Ruedas (estado, presión, check points
                            <br /> de tuercas, piedras) Oruga
                          </FormLabel>
                          {handleValue(seleccionado.ruedasMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Estructura / Chasis
                          </FormLabel>
                          {handleValue(seleccionado.estructuraMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Alemites
                          </FormLabel>
                          {handleValue(seleccionado.alemitesMP)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Mangueras, correas
                          </FormLabel>
                          {handleValue(seleccionado.manguerasMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Pérdidas de fluidos
                          </FormLabel>
                          {handleValue(seleccionado.perdidasFluidoMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Balde
                          </FormLabel>
                          {handleValue(seleccionado.baldeMP)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Extintor (carga, vencimiento, clavija)
                          </FormLabel>
                          {handleValue(seleccionado.extintorMP)}
                        </HStack>
                      </FormControl>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "Dosificadora" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        {seleccionado.equipoRevision}
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique la alineacion de la banda
                          </FormLabel>
                          {handleValue(seleccionado.alineacionBandaDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verificar si algun rodillo de
                            <br /> las estaciones presenta desgaste
                          </FormLabel>
                          {handleValue(seleccionado.desgasteRodilloDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique el desgaste de la cuchilla
                            <br /> en el rolo tractor
                          </FormLabel>

                          {handleValue(seleccionado.desgasteRoloTractorDosi)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique el estado de la banda
                          </FormLabel>
                          {handleValue(seleccionado.estadoBandaDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique el estado del faldon de
                            <br /> hule y ajustar de ser necesario
                          </FormLabel>
                          {handleValue(seleccionado.estadoFaldonHuleDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Limpieza de rodillos de las estaciones,
                            <br /> si presenta acumulacion de material
                          </FormLabel>

                          {handleValue(seleccionado.materialEnRodilloDosi)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique el nivel de aceite hidraulico
                          </FormLabel>
                          {handleValue(seleccionado.nvlAceiteHidraDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verificar que los rodillos de las estaciones
                            <br /> giren libremente
                          </FormLabel>
                          {handleValue(seleccionado.rodillosDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verificar que haya separacion entre el <br />
                            protector de retorno y el rodillo de retorno
                          </FormLabel>

                          {handleValue(
                            seleccionado.separacionProtectorRodilloDosi
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique la tension de la banda
                          </FormLabel>
                          {handleValue(seleccionado.tensionBandaDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Inspección del reductor del Conjunto de <br />{" "}
                            transmision para radiado (zona de cubierta)
                          </FormLabel>
                          {handleValue(seleccionado.transmisionDosi)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verifique la unión de banda
                          </FormLabel>
                          {handleValue(seleccionado.unionBandaDosi)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <Stack py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Observaciones
                          </FormLabel>
                          <Text>{seleccionado.observacionesDosi}</Text>
                        </Stack>
                      </FormControl>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "BaseSandvan" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        <Text fontSize="sm">{seleccionado.equipoRevision}</Text>
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Funcionamiento de sistema de aire en compresor de
                            aire
                          </FormLabel>
                          {handleValue(seleccionado.funcionamientoAireaBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Funcionamiento de llaves de paso en compresor de
                            aire
                          </FormLabel>
                          {handleValue(seleccionado.funcionamientoPasoaBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Indicadores de voltaje externo
                          </FormLabel>
                          {handleValue(seleccionado.indicadorVoltajeBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Luminaria externa
                          </FormLabel>
                          {handleValue(seleccionado.luminariaExtBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero externo
                          </FormLabel>
                          {handleValue(seleccionado.tableroExtBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero interno P1
                          </FormLabel>
                          {handleValue(seleccionado.tableroIntP1BSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero interno P2
                          </FormLabel>
                          {handleValue(seleccionado.tableroIntP2BSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero interno P3
                          </FormLabel>
                          {handleValue(seleccionado.tableroIntP3BSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero interno P4
                          </FormLabel>
                          {handleValue(seleccionado.tableroIntP4BSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tomas electricas externas
                          </FormLabel>
                          {handleValue(seleccionado.tomasExtBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verificar nivel de aceite en compresor de aire
                          </FormLabel>
                          {handleValue(seleccionado.verificarAceiteBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verificar nivel de combustible en compresor de aire
                          </FormLabel>
                          {handleValue(seleccionado.verificarCombustibleBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Verificar estado de la corre en compresor de aire
                          </FormLabel>
                          {handleValue(seleccionado.verificarCorreaBSV)}
                        </HStack>
                      </FormControl>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "SandVan" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        <Text fontSize="sm">{seleccionado.equipoRevision}</Text>
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Aire acondicionado
                          </FormLabel>
                          {handleValue(seleccionado.acSV)}
                        </HStack>
                      </FormControl>

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Antenas sistema de camara
                          </FormLabel>
                          {handleValue(seleccionado.antenaCamaraSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sistema de camaras Sander A
                          </FormLabel>
                          {handleValue(seleccionado.camaraSanderASV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sistema de camaras sander B
                          </FormLabel>
                          {handleValue(seleccionado.camaraSanderBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Limpieza de filtro pesurizador
                          </FormLabel>
                          {handleValue(seleccionado.limpiezaFiltroSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Luminarias internas
                          </FormLabel>
                          {handleValue(seleccionado.luminariasIntSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Manga de viento
                          </FormLabel>
                          {handleValue(seleccionado.mangaVientoSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Matafuego
                          </FormLabel>
                          {handleValue(seleccionado.matafuegoSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Monitores
                          </FormLabel>
                          {handleValue(seleccionado.monitoresSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Notebook de fractura
                          </FormLabel>
                          {handleValue(seleccionado.notebookFracturaSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Notebook de sander
                          </FormLabel>
                          {handleValue(seleccionado.notebookSanderSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Presurizador
                          </FormLabel>
                          {handleValue(seleccionado.presurizadorSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Reflectores externos
                          </FormLabel>
                          {handleValue(seleccionado.reflectoresExternosSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sillas
                          </FormLabel>
                          {handleValue(seleccionado.sillasSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sistema de camaras Sander A
                          </FormLabel>
                          {handleValue(seleccionado.sistemaCamarasSanderAWET)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sistema de camaras sander B
                          </FormLabel>
                          {handleValue(seleccionado.sistemaCamarasSanderBWET)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Sistema camaras Dosificadora
                          </FormLabel>
                          {handleValue(seleccionado.sistemaDosificadoraWET)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero de comando
                          </FormLabel>
                          {handleValue(seleccionado.tableroComandoWET)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero de comando sander A
                          </FormLabel>
                          {handleValue(seleccionado.tableroSanderASV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tablero de comando sander B
                          </FormLabel>
                          {handleValue(seleccionado.tableroSanderBSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Techo rebatible
                          </FormLabel>
                          {handleValue(seleccionado.techoRebatibleSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Tomas internas
                          </FormLabel>
                          {handleValue(seleccionado.tomasInternasSV)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P1 SA WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP1SAWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P1 SB WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP1SBWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P2 SA WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP2SAWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            AActivacion de vibradores P2 SB WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP2SBWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P3 SA WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP3SAWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P3 SB WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP3SBWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P4 SA WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP4SAWET
                          )}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Activacion de vibradores P4 SB WET
                          </FormLabel>
                          {handleValue(
                            seleccionado.activacionVibradoresP4SBWET
                          )}
                        </HStack>
                      </FormControl>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "Sander" && (
                    <CardBody overflow="auto">
                      <Heading size="md" py={2}>
                        Sander principal
                      </Heading>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">Sander</FormLabel>
                          <Text>{seleccionado.codigoSanderA}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            QR Code A1
                          </FormLabel>
                          <Text>{seleccionado.qrCodeA1}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Power Pack A
                          </FormLabel>
                          <Text>{seleccionado.powerPackA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Horas Motor A
                          </FormLabel>
                          <Text>{seleccionado.horasMotorA}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Combustible: Nivel
                          </FormLabel>
                          <Text>{seleccionado.nivelCombustibleA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Perdidas Combustible
                          </FormLabel>
                          {handleValue(seleccionado.perdidasCombustibleA)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Presion Aceite
                          </FormLabel>
                          <Text>{seleccionado.presionAceiteA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel Aceite
                          </FormLabel>
                          {handleValue(seleccionado.nivelAceiteA)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Temperatura Refrigerante
                          </FormLabel>
                          <Text>{seleccionado.tempRefrigeranteA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Perdidas Refrigerante
                          </FormLabel>
                          {handleValue(seleccionado.perdidasRefrigeranteA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel Refrigerante
                          </FormLabel>
                          {handleValue(seleccionado.nivelRefrigeranteA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Refrigerante: Sin pérdidas?
                          </FormLabel>
                          {handleValue(seleccionado.refrigerantePerdidaA)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Eléctrico: Estado borneras
                          </FormLabel>
                          {handleValue(seleccionado.estadoBorneasA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Limpieza de filtro primario de aire
                          </FormLabel>
                          {handleValue(seleccionado.limpiezaFiltroAireA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">Limpieza</FormLabel>
                          {handleValue(seleccionado.limpizaA)}
                        </HStack>
                      </FormControl>
                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            QR Code A.2
                          </FormLabel>
                          <Text>{seleccionado.qrCodeA2A}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Banda Transportadora
                          </FormLabel>
                          {handleValue(seleccionado.bandaTransportadoraA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Alineación de Banda
                          </FormLabel>
                          {handleValue(seleccionado.alineacionBandaA)}
                        </HStack>
                      </FormControl>

                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Rolos de Retorno
                          </FormLabel>
                          {handleValue(seleccionado.rolosRetornoA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Rolos ventilados
                          </FormLabel>
                          {handleValue(seleccionado.rolosVentiladosA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Rolos Sdrive
                          </FormLabel>
                          {handleValue(seleccionado.rolosSdriveA)}
                        </HStack>
                      </FormControl>

                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Unión de Banda
                          </FormLabel>
                          {handleValue(seleccionado.unionBandaA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cadena de Transmisión de cinta principal
                          </FormLabel>
                          {handleValue(seleccionado.ctcpA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Acoplamiento de Cinta Frontal
                          </FormLabel>
                          {handleValue(seleccionado.acoplamientoCFA)}
                        </HStack>
                      </FormControl>

                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Anclaje placas de celdas
                          </FormLabel>
                          {handleValue(seleccionado.anclajePlacaCeldasA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Lubricación de rodamientos
                          </FormLabel>

                          {handleValue(seleccionado.lubricacionRodamientosA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Lubricación de cadena
                          </FormLabel>
                          {handleValue(seleccionado.lubricacionCadenaA)}
                        </HStack>
                      </FormControl>

                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            QR Code A.3
                          </FormLabel>
                          <Text>{seleccionado.qrCodeA3A}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Horas Circuito Hidráulico (Q.Variable)
                          </FormLabel>
                          <Text>{seleccionado.hsCircuitoHidraA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Aceite Hidráulico: Temperatura
                          </FormLabel>
                          <Text>{seleccionado.tempAceiteHidraA}</Text>
                        </HStack>
                      </FormControl>

                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Aceite Hidráulico: Nivel
                          </FormLabel>
                          {handleValue(seleccionado.nivelAceiteHidraA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Aceite Hidráulico: Sin Pérdidas?
                          </FormLabel>
                          {handleValue(seleccionado.aceiteHidraPerdidaA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Movimiento de Carros
                          </FormLabel>
                          {handleValue(seleccionado.movimientoCarrosA)}
                        </HStack>
                      </FormControl>

                      <Divider />

                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Presión de retorno de bomba de engranajes
                          </FormLabel>
                          <Text>{seleccionado.presionRetonoBEA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Presión de StandBy de bomba de pistones
                          </FormLabel>
                          <Text>{seleccionado.presionSantByBPA}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Presión de trabajo de bomba de pistones
                          </FormLabel>
                          <Text>{seleccionado.presionTrabajoBPA}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Tableros cerrados
                          </FormLabel>
                          {handleValue(seleccionado.tablerosCerradosA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cable de comando
                          </FormLabel>
                          {handleValue(seleccionado.cableComandoA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Calibración de balanzas
                          </FormLabel>
                          {handleValue(seleccionado.calibracionBalanzaA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Funcionamiento Semáforos y Luces
                          </FormLabel>
                          {handleValue(seleccionado.semaforoLucesA)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">
                          Observaciones y Comentarios
                        </FormLabel>
                        <Text>{seleccionado.observacionesSA}</Text>
                      </FormControl>
                      <Divider />
                      <Heading size="md" py={2}>
                        Sander BackUp
                      </Heading>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">Equipo</FormLabel>
                          <Text>{seleccionado.codigoSanderB}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            QR Code B1
                          </FormLabel>
                          <Text>{seleccionado.qrCodeB1}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Power Pack B
                          </FormLabel>
                          <Text>{seleccionado.powerPackB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Horas Motor B
                          </FormLabel>
                          <Text>{seleccionado.horasMotorB}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Combustible: Nivel
                          </FormLabel>
                          <Text>{seleccionado.nivelCombustibleB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Perdidas Combustible
                          </FormLabel>
                          {handleValue(seleccionado.perdidasCombustibleB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Presion Aceite
                          </FormLabel>
                          <Text>{seleccionado.presionAceiteB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel Aceite
                          </FormLabel>
                          {handleValue(seleccionado.nivelAceiteB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Temperatura Refrigerante
                          </FormLabel>
                          <Text>{seleccionado.tempRefrigeranteB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Perdidas Refrigerante
                          </FormLabel>

                          {handleValue(seleccionado.perdidasRefrigeranteB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel Refrigerante
                          </FormLabel>
                          {handleValue(seleccionado.nivelRefrigeranteB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Refrigerante: Sin pérdidas?
                          </FormLabel>
                          {handleValue(seleccionado.refrigerantePerdidaB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">
                            Eléctrico: Estado borneras
                          </FormLabel>
                          {handleValue(seleccionado.estadoBorneasB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Limpieza de filtro primario de aire
                          </FormLabel>
                          {handleValue(seleccionado.limpiezaFiltroAireB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold">Limpieza</FormLabel>
                          {handleValue(seleccionado.limpizaB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            QR Code B.2
                          </FormLabel>
                          <Text>{seleccionado.qrCodeB2}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Banda Transportadora
                          </FormLabel>
                          {handleValue(seleccionado.bandaTransportadoraB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Alineación de Banda
                          </FormLabel>
                          {handleValue(seleccionado.alineacionBandaB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Rolos de Retorno
                          </FormLabel>
                          {handleValue(seleccionado.rolosRetornoB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Rolos ventilados
                          </FormLabel>
                          {handleValue(seleccionado.rolosVentiladosB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Rolos Sdrive
                          </FormLabel>
                          {handleValue(seleccionado.rolosSdriveB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Unión de Banda
                          </FormLabel>
                          {handleValue(seleccionado.unionBandaB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cadena de Transmisión de cinta principal
                          </FormLabel>
                          {handleValue(seleccionado.ctcpB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Acoplamiento de Cinta Frontal
                          </FormLabel>
                          {handleValue(seleccionado.acoplamientoCFB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Anclaje placas de celdas
                          </FormLabel>
                          {handleValue(seleccionado.anclajePlacaCeldasB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Lubricación de rodamientos
                          </FormLabel>

                          {handleValue(seleccionado.lubricacionRodamientosB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Lubricación de cadena
                          </FormLabel>
                          {handleValue(seleccionado.lubricacionCadenaB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            QR Code B.3
                          </FormLabel>
                          <Text>{seleccionado.qrCodeA3B}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Horas Circuito Hidráulico (Q.Variable)
                          </FormLabel>
                          <Text>{seleccionado.hsCircuitoHidraB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Aceite Hidráulico: Temperatura
                          </FormLabel>
                          <Text>{seleccionado.tempAceiteHidraB}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Aceite Hidráulico: Nivel
                          </FormLabel>
                          {handleValue(seleccionado.nivelAceiteHidraB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Aceite Hidráulico: Sin Pérdidas?
                          </FormLabel>
                          {handleValue(seleccionado.aceiteHidraPerdidaB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Movimiento de Carros
                          </FormLabel>
                          {handleValue(seleccionado.movimientoCarrosB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Presión de retorno de bomba de engranajes
                          </FormLabel>
                          <Text>{seleccionado.presionRetonoBEB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Presión de StandBy de bomba de pistones
                          </FormLabel>
                          <Text>{seleccionado.presionSantByBPB}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Presión de trabajo de bomba de pistones
                          </FormLabel>
                          <Text>{seleccionado.presionTrabajoBPB}</Text>
                        </HStack>
                      </FormControl>
                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold">
                            Tableros cerrados
                          </FormLabel>
                          {handleValue(seleccionado.tablerosCerradosA)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cable de comando
                          </FormLabel>
                          {handleValue(seleccionado.cableComandoB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Calibración de balanzas
                          </FormLabel>
                          {handleValue(seleccionado.calibracionBalanzaB)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Funcionamiento Semáforos y Luces
                          </FormLabel>
                          {handleValue(seleccionado.semaforoLucesB)}
                        </HStack>
                      </FormControl>
                      <Divider />
                      <HStack py={1}>
                        <FormControl alignSelf="start">
                          <FormLabel fontWeight="semibold">
                            Observaciones y Comentarios
                          </FormLabel>
                          {seleccionado.observacionesSB}
                        </FormControl>
                      </HStack>
                      <Divider />
                      <HStack py={1}>
                        <FormControl alignSelf="start">
                          <FormLabel fontWeight="semibold">
                            Observaciones y Comentarios general
                          </FormLabel>
                          {seleccionado.observacionesGral}
                        </FormControl>
                      </HStack>
                    </CardBody>
                  )}
                  {seleccionado.equipoRevision === "Luminaria" && (
                    <CardBody overflow="auto">
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold">Equipo</FormLabel>
                        <Text fontSize="sm">{seleccionado.equipoRevision}</Text>
                      </FormControl>

                      <Divider />
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Arrestallama
                          </FormLabel>
                          {handleValue(seleccionado.arrestallamaLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Bandeja antiderrame
                          </FormLabel>
                          {handleValue(seleccionado.bandejaLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Batería / Alternador
                          </FormLabel>
                          {handleValue(seleccionado.bateriaAlterLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Cable de conexión
                          </FormLabel>
                          {handleValue(seleccionado.cableLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Estado de correa
                          </FormLabel>
                          {handleValue(seleccionado.estadoCorreaLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Estructura general de equipo
                          </FormLabel>
                          {handleValue(seleccionado.estructuraLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Lamparas / Focos
                          </FormLabel>
                          {handleValue(seleccionado.lamparaFocoLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Motor
                          </FormLabel>
                          {handleValue(seleccionado.motorLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel de aceite
                          </FormLabel>
                          {handleValue(seleccionado.nivelAceiteLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Parada de emergencia
                          </FormLabel>
                          {handleValue(seleccionado.paradaLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Nivel de refrigerante
                          </FormLabel>
                          {handleValue(seleccionado.nivelRefrigeranteLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Puesta a tierra
                          </FormLabel>
                          {handleValue(seleccionado.puestaLum)}
                        </HStack>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <HStack justifyContent="space-between" py={1}>
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Torre de iluminacion
                          </FormLabel>
                          {handleValue(seleccionado.torreLum)}
                        </HStack>
                      </FormControl>
                    </CardBody>
                  )}
                </Card>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {!seleccionado && (
          <Center w="xl" justifyContent={"center"}>
            <Text
              fontSize={"14px"}
              color={"gray.400"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              La información se verá una vez <br /> que se seleccione una
              revisión
            </Text>
          </Center>
        )}
      </HStack>
    </Stack>
  );
};

export default RevisionTurnos;
