import React, { useState, useEffect } from "react";
import {
  HStack,
  Switch,
  FormControl,
  Stack,
  FormLabel,
  Button,
  Select,
  useToast,
  Heading,
  Text,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import { MdNavigateNext, MdArrowDropDown, MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionMiniPala,
  editarRevisionMiniPala,
  getPlanificaciones,
  getRevisionMiniPala,
} from "../../../helpers/api.helper";
import { validateField } from "../../../../../utils/Validaciones";

const RevisionMiniPala = (props) => {
  const { tipo, user } = props;
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [editable, setEditable] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { padLocacion } = useParams();
  const { id } = useParams();

  const initUsarios = async () => {
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones({ padLocacion: padLocacion });

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getRevisionMiniPala();
    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }

      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter((e) => e.idRevisionMP === parseInt(id));

          return a[0];
        });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    initUsarios();
  }, []);
  let initialValues =
    tipo === "editar"
      ? {
          documentacionMP: editable.documentacionMP,
          cabinaMP: editable.cabinaMP,
          instrumentalMP: editable.instrumentalMP,
          lucesMP: editable.lucesMP,
          asientoMP: editable.asientoMP,
          cinturonMP: editable.cinturonMP,
          pernosMP: editable.pernosMP,
          alarmasMP: editable.alarmasMP,
          bocinaMP: editable.bocinaMP,
          tapaTanqueMP: editable.tapaTanqueMP,
          valvulasMP: editable.valvulasMP,
          sistemaElectricoMP: editable.sistemaElectricoMP,
          motorMP: editable.motorMP,
          nivelFluidosMP: editable.nivelFluidosMP,
          sistemaHidraulicoMP: editable.sistemaHidraulicoMP,
          transmisionMP: editable.transmisionMP,
          engraseMP: editable.engraseMP,
          sistemaDireccionMP: editable.sistemaDireccionMP,
          bloqueosMP: editable.bloqueosMP,
          cilindrosMP: editable.cilindrosMP,
          arrestallamasMP: editable.arrestallamasMP,
          ruedasMP: editable.ruedasMP,
          estructuraMP: editable.estructuraMP,
          alemitesMP: editable.alemitesMP,
          manguerasMP: editable.manguerasMP,
          perdidasFluidoMP: editable.perdidasFluidoMP,
          baldeMP: editable.baldeMP,
          extintorMP: editable.extintorMP,
          observacionesMP: editable.observacionesMP,
          responsable: editable.responsable,
        }
      : {
          documentacionMP: false,
          cabinaMP: false,
          instrumentalMP: false,
          lucesMP: false,
          asientoMP: false,
          cinturonMP: false,
          pernosMP: false,
          alarmasMP: false,
          bocinaMP: false,
          tapaTanqueMP: false,
          valvulasMP: false,
          sistemaElectricoMP: false,
          motorMP: false,
          nivelFluidosMP: false,
          sistemaHidraulicoMP: false,
          transmisionMP: false,
          engraseMP: false,
          sistemaDireccionMP: false,
          bloqueosMP: false,
          cilindrosMP: false,
          arrestallamasMP: false,
          ruedasMP: false,
          estructuraMP: false,
          alemitesMP: false,
          manguerasMP: false,
          perdidasFluidoMP: false,
          baldeMP: false,
          extintorMP: false,
          observacionesMP: "",
          responsable: "",
        };

  return (
    <Stack w="100%" h={"100%"} overflow={"auto"}>
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"}>Revision Mini pala</Heading>
      </HStack>

      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.responsable) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Responsable requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let data =
              tipo === "editar"
                ? {
                    responsable: values.responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    id: editable.idRevisionMP,
                    documentacionMP: values.documentacionMP,
                    cabinaMP: values.cabinaMP,
                    instrumentalMP: values.instrumentalMP,
                    lucesMP: values.lucesMP,
                    asientoMP: values.asientoMP,
                    cinturonMP: values.cinturonMP,
                    pernosMP: values.pernosMP,
                    alarmasMP: values.alarmasMP,
                    bocinaMP: values.bocinaMP,
                    tapaTanqueMP: values.tapaTanqueMP,
                    valvulasMP: values.valvulasMP,
                    sistemaElectricoMP: values.sistemaElectricoMP,
                    motorMP: values.motorMP,
                    nivelFluidosMP: values.nivelFluidosMP,
                    sistemaHidraulicoMP: values.sistemaHidraulicoMP,
                    transmisionMP: values.transmisionMP,
                    engraseMP: values.engraseMP,
                    sistemaDireccionMP: values.sistemaDireccionMP,
                    bloqueosMP: values.bloqueosMP,
                    cilindrosMP: values.cilindrosMP,
                    arrestallamasMP: values.arrestallamasMP,
                    ruedasMP: values.ruedasMP,
                    estructuraMP: values.estructuraMP,
                    alemitesMP: values.alemitesMP,
                    manguerasMP: values.manguerasMP,
                    perdidasFluidoMP: values.perdidasFluidoMP,
                    baldeMP: values.baldeMP,
                    extintorMP: values.extintorMP,
                    equipoRevision: "Mini Pala",
                    observacionesMP: values.observacionesMP,
                  }
                : {
                    responsable: values.responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    documentacionMP: values.documentacionMP,
                    cabinaMP: values.cabinaMP,
                    instrumentalMP: values.instrumentalMP,
                    lucesMP: values.lucesMP,
                    asientoMP: values.asientoMP,
                    cinturonMP: values.cinturonMP,
                    pernosMP: values.pernosMP,
                    alarmasMP: values.alarmasMP,
                    bocinaMP: values.bocinaMP,
                    tapaTanqueMP: values.tapaTanqueMP,
                    valvulasMP: values.valvulasMP,
                    sistemaElectricoMP: values.sistemaElectricoMP,
                    motorMP: values.motorMP,
                    nivelFluidosMP: values.nivelFluidosMP,
                    sistemaHidraulicoMP: values.sistemaHidraulicoMP,
                    transmisionMP: values.transmisionMP,
                    engraseMP: values.engraseMP,
                    sistemaDireccionMP: values.sistemaDireccionMP,
                    bloqueosMP: values.bloqueosMP,
                    cilindrosMP: values.cilindrosMP,
                    arrestallamasMP: values.arrestallamasMP,
                    ruedasMP: values.ruedasMP,
                    estructuraMP: values.estructuraMP,
                    alemitesMP: values.alemitesMP,
                    manguerasMP: values.manguerasMP,
                    perdidasFluidoMP: values.perdidasFluidoMP,
                    baldeMP: values.baldeMP,
                    extintorMP: values.extintorMP,
                    equipoRevision: "Mini Pala",
                    observacionesMP: values.observacionesMP,
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionMiniPala(data);
            } else {
              res = await crearRevisionMiniPala(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de mini pala ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de mini pala ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de mini pala`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form id="miniPala">
              <HStack px={7}>
                <FormControl isRequired>
                  <FormLabel>Responsable</FormLabel>
                  <Select
                    w="2xs"
                    size="sm"
                    placeholder="Seleccione responsable"
                    icon={<MdArrowDropDown />}
                    onChange={handleChange}
                    value={values.responsable}
                    name="responsable"
                  >
                    {usuarios
                      .sort((a, b) => {
                        // Ordenar alfabéticamente por nombre y apellido
                        const nameA = `${a.nombre}`.toUpperCase();
                        const nameB = `${b.nombre}`.toUpperCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                      })
                      .map((usuario) => (
                        <option
                          key={usuario.idUsuario}
                          value={usuario.idUsuario}
                        >
                          {usuario.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </HStack>
              <HStack w="100%" justifyContent="space-between" p={5}>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.documentacionMP}
                      name="documentacionMP"
                    />
                    <Text>Documentos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.cabinaMP}
                      name="cabinaMP"
                    />
                    <Text>
                      Cabina, Vidrio, Jaula <br /> (interio, exterior)
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.instrumentalMP}
                      name="instrumentalMP"
                    />
                    <Text>Instrumental / Comandos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.lucesMP}
                      name="lucesMP"
                    />
                    <Text>Luces, espejos y retrovisores</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.asientoMP}
                      name="asientoMP"
                    />
                    <Text>Asiento (sensor, trabas y estado)</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.cinturonMP}
                      name="cinturonMP"
                    />
                    <Text>Cinturon de Seguridad</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.pernosMP}
                      name="pernosMP"
                    />
                    <Text>Pernos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.alarmasMP}
                      name="alarmasMP"
                    />
                    <Text>Alarmas de alertas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.bocinaMP}
                      name="bocinaMP"
                    />
                    <Text>Bocina, alarma de retroceso</Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tapaTanqueMP}
                      name="tapaTanqueMP"
                    />
                    <Text>Tapa tanque de combustible</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.valvulasMP}
                      name="valvulasMP"
                    />
                    <Text>Válvulas de seguridad</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.sistemaElectricoMP}
                      name="sistemaElectricoMP"
                    />
                    <Text>Sistema electrico / Bateria</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.motorMP}
                      name="motorMP"
                    />
                    <Text>Motor</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.nivelFluidosMP}
                      name="nivelFluidosMP"
                    />
                    <Text>Nivel de fluidos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.sistemaHidraulicoMP}
                      name="sistemaHidraulicoMP"
                    />
                    <Text>Sistema hidraulico</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.transmisionMP}
                      name="transmisionMP"
                    />
                    <Text>Transmision</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.engraseMP}
                      name="engraseMP"
                    />
                    <Text>Engrase</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.sistemaDireccionMP}
                      name="sistemaDireccionMP"
                    />
                    <Text>Sistema de dirección</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.bloqueosMP}
                      name="bloqueosMP"
                    />
                    <Text>
                      Bloqueos (parking, baranda de seguridad,
                      <br /> boton de accionamiento)
                    </Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.cilindrosMP}
                      name="cilindrosMP"
                    />
                    <Text>Cilindros hidráulicos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.arrestallamasMP}
                      name="arrestallamasMP"
                    />
                    <Text>Arrestallamas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.ruedasMP}
                      name="ruedasMP"
                    />
                    <Text>
                      Ruedas (estado, presión, check points
                      <br /> de tuercas, piedras) Oruga
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.estructuraMP}
                      name="estructuraMP"
                    />
                    <Text>Estructura / Chasis</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.alemitesMP}
                      name="alemitesMP"
                    />
                    <Text>Alemites</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.manguerasMP}
                      name="manguerasMP"
                    />
                    <Text>Mangueras, correas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.perdidasFluidoMP}
                      name="perdidasFluidoMP"
                    />
                    <Text>Pérdidas de fluidos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.baldeMP}
                      name="baldeMP"
                    />
                    <Text>Balde</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.extintorMP}
                      name="extintorMP"
                    />
                    <Text>Extintor (carga, vencimiento, clavija)</Text>
                  </HStack>
                </Stack>
              </HStack>
              <HStack w="100%" p={3} justifyContent="space-between">
                <Stack placeSelf={"start"} w={"100%"}>
                  <Field
                    name="observacionesMP"
                    validate={(value) =>
                      validateField("textArea", value, "Observaciones")
                    }
                    w={"100%"}
                  >
                    {({ field }) => (
                      <FormControl
                        isInvalid={
                          errors?.observacionesMP && touched?.observacionesMP
                        }
                      >
                        <FormLabel>Observaciones</FormLabel>
                        <Card variant="unstyled" bg="brand.fondos_secundarios">
                          <CardBody>
                            <Textarea
                              {...field}
                              placeholder="Descripcion"
                              name="observacionesMP"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="1500"
                              resize="none"
                            />
                          </CardBody>
                          <CardFooter
                            placeSelf="end"
                            color="brand.gris_medio"
                            pr={2}
                            m={"unset"}
                          >
                            {values.observacionesMP.length}/1500
                          </CardFooter>
                        </Card>
                        <FormErrorMessage>
                          {errors?.observacionesMP}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>

                <Button
                  fontSize={12}
                  fontWeight="semibold"
                  bg="brand.naranja"
                  color="white"
                  rightIcon={<MdNavigateNext fontSize="20px" />}
                  variant="solid"
                  form="miniPala"
                  onClick={handleSubmit}
                  isDisabled={loading || user.idRol === "usuarioIT"}
                  _hover={{ bg: "white", color: "brand.naranja" }}
                >
                  Finalizar
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionMiniPala;
