import React, { useState, useEffect } from "react";
import {
  HStack,
  FormControl,
  Stack,
  FormLabel,
  Button,
  Select,
  useToast,
  Heading,
  Text,
  Switch,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import { MdNavigateNext, MdArrowDropDown, MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  getPlanificaciones,
  crearRevisionLuminaria,
  editarRevisionLuminaria,
  getRevisionLuminaria,
} from "../../../helpers/api.helper";
import { validateField } from "../../../../../utils/Validaciones";

const RevisionLuminaria = (props) => {
  const { tipo, user } = props;
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [editable, setEditable] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { padLocacion } = useParams();
  const { id } = useParams();

  const initData = async () => {
    setLoading(true);
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones({ padLocacion: padLocacion });

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getRevisionLuminaria();

    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }

      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter((e) => e.idRevisionLum === parseInt(id));

          return a[0];
        });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  let initialValues =
    tipo === "editar"
      ? {
          estructuraLum: editable.estructuraLum,
          torreLum: editable.torreLum,
          lamparaFocoLum: editable.lamparaFocoLum,
          motorLum: editable.motorLum,
          nivelAceiteLum: editable.nivelAceiteLum,
          nivelRefrigeranteLum: editable.nivelRefrigeranteLum,
          estadoCorreaLum: editable.estadoCorreaLum,
          paradaLum: editable.paradaLum,
          bateriaAlterLum: editable.bateriaAlterLum,
          puestaLum: editable.puestaLum,
          bandejaLum: editable.bandejaLum,
          arrestallamaLum: editable.arrestallamaLum,
          cableLum: editable.cableLum,
          observacionesLum: editable.observacionesLum || "",
          responsable: editable.responsable,
          fechaRevision: editable.fechaRevision,
        }
      : {
          estructuraLum: false,
          torreLum: false,
          lamparaFocoLum: false,
          motorLum: false,
          nivelAceiteLum: false,
          nivelRefrigeranteLum: false,
          estadoCorreaLum: false,
          paradaLum: false,
          bateriaAlterLum: false,
          puestaLum: false,
          bandejaLum: false,
          arrestallamaLum: false,
          cableLum: false,
          observacionesLum: "",
          responsable: "",
          fechaRevision: "",
        };

  return (
    <Stack w="100%" overflow={"auto"}>
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"} px={2}>
          Revision Luminaria
        </Heading>
      </HStack>

      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.responsable) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Responsable requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let data =
              tipo === "editar"
                ? {
                    estructuraLum: values.estructuraLum,
                    torreLum: values.torreLum,
                    lamparaFocoLum: values.lamparaFocoLum,
                    motorLum: values.motorLum,
                    nivelAceiteLum: values.nivelAceiteLum,
                    nivelRefrigeranteLum: values.nivelRefrigeranteLum,
                    estadoCorreaLum: values.estadoCorreaLum,
                    paradaLum: values.paradaLum,
                    bateriaAlterLum: values.bateriaAlterLum,
                    puestaLum: values.puestaLum,
                    bandejaLum: values.bandejaLum,
                    arrestallamaLum: values.arrestallamaLum,
                    cableLum: values.cableLum,
                    observacionesLum: values.observacionesLum,
                    id: editable.idRevisionLum,
                    responsable: values.responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    equipoRevision: "Luminaria",
                  }
                : {
                    estructuraLum: values.estructuraLum,
                    torreLum: values.torreLum,
                    lamparaFocoLum: values.lamparaFocoLum,
                    motorLum: values.motorLum,
                    nivelAceiteLum: values.nivelAceiteLum,
                    nivelRefrigeranteLum: values.nivelRefrigeranteLum,
                    estadoCorreaLum: values.estadoCorreaLum,
                    paradaLum: values.paradaLum,
                    bateriaAlterLum: values.bateriaAlterLum,
                    puestaLum: values.puestaLum,
                    bandejaLum: values.bandejaLum,
                    arrestallamaLum: values.arrestallamaLum,
                    cableLum: values.cableLum,
                    observacionesLum: values.observacionesLum,
                    responsable: values.responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    equipoRevision: "Luminaria",
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionLuminaria(data);
            } else {
              res = await crearRevisionLuminaria(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de luminaria ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de luminaria ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de luminaria`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form id="luminaria">
              <HStack px={7} pb={3}>
                <FormControl isRequired>
                  <FormLabel>Responsable</FormLabel>
                  <Select
                    w="2xs"
                    size="sm"
                    placeholder="Seleccione responsable"
                    icon={<MdArrowDropDown />}
                    onChange={handleChange}
                    value={values.responsable}
                    name="responsable"
                  >
                    {usuarios
                      .sort((a, b) => {
                        // Ordenar alfabéticamente por nombre y apellido
                        const nameA = `${a.nombre}`.toUpperCase();
                        const nameB = `${b.nombre}`.toUpperCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                      })
                      .map((usuario) => (
                        <option
                          key={usuario.idUsuario}
                          value={usuario.idUsuario}
                        >
                          {usuario.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </HStack>
              <HStack
                w="100%"
                justifyContent={"space-between"}
                spacing={20}
                px={7}
                pb={3}
              >
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.estructuraLum}
                      name="estructuraLum"
                    />
                    <Text>Estructura general de equipo</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.torreLum}
                      name="torreLum"
                    />
                    <Text>Torre de iluminacion</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.lamparaFocoLum}
                      name="lamparaFocoLum"
                    />
                    <Text>Lamparas / Focos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.motorLum}
                      name="motorLum"
                    />
                    <Text>Motor (si aplica)</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.nivelAceiteLum}
                      name="nivelAceiteLum"
                    />
                    <Text>Nivel de aceite (si aplica)</Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.nivelRefrigeranteLum}
                      name="nivelRefrigeranteLum"
                    />
                    <Text>Nivel de refrigerante (si aplica)</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.estadoCorreaLum}
                      name="estadoCorreaLum"
                    />
                    <Text>Estado de correa (si aplica)</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.paradaLum}
                      name="paradaLum"
                    />
                    <Text>Parada de emergencia</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.bateriaAlterLum}
                      name="bateriaAlterLum"
                    />
                    <Text>Bateria / Alternador</Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.puestaLum}
                      name="puestaLum"
                    />
                    <Text>Puesta a tierra</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.bandejaLum}
                      name="bandejaLum"
                    />
                    <Text>Bandeja antiderrame</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.arrestallamaLum}
                      name="arrestallamaLum"
                    />
                    <Text>Arrestallama</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.cableLum}
                      name="cableLum"
                    />
                    <Text>Cable de conexión</Text>
                  </HStack>
                </Stack>
              </HStack>
              <HStack w="100%" justifyContent="space-between" px={7}>
                <Stack placeSelf={"start"} w={"100%"}>
                  <Field
                    name="observacionesLum"
                    validate={(value) =>
                      validateField("textArea", value, "Observaciones")
                    }
                    w={"100%"}
                  >
                    {({ field }) => (
                      <FormControl
                        isInvalid={
                          errors?.observacionesLum && touched?.observacionesLum
                        }
                      >
                        <FormLabel>Observaciones</FormLabel>
                        <Card variant="unstyled" bg="brand.fondos_secundarios">
                          <CardBody>
                            <Textarea
                              {...field}
                              placeholder="Descripcion"
                              name="observacionesLum"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="1500"
                              resize="none"
                            />
                          </CardBody>
                          <CardFooter
                            placeSelf="end"
                            color="brand.gris_medio"
                            pr={2}
                            m={"unset"}
                          >
                            {values.observacionesLum.length}/1500
                          </CardFooter>
                        </Card>
                        <FormErrorMessage>
                          {errors?.observacionesLum}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>

                <Button
                  placeSelf={"center"}
                  fontSize={12}
                  fontWeight="semibold"
                  bg="brand.naranja"
                  color="white"
                  rightIcon={<MdNavigateNext fontSize="20px" />}
                  variant="solid"
                  form="luminaria"
                  onClick={handleSubmit}
                  isDisabled={loading || user.idRol === "usuarioIT"}
                  _hover={{ bg: "white", color: "brand.naranja" }}
                >
                  Finalizar
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionLuminaria;
